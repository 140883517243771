<template>

    <div class="menu-box">
        <dv-decoration-3 style="width:93px;height:30px;" />
        <div class="box">
<!--            //<dv-border-box-8 style="padding: 8px;border-radius: 4px">-->
                <template v-for="item in links">
                    <a v-if="item.type" class="menu_title" :href="item.link"
                       :key="item.link">
                        <i :class="item.icon" style="font-size: 22px;margin-bottom: 4px"></i>
                        {{ item.title }}
                    </a>
                    <router-link v-else class="menu_title" :to="item.link" :key="item.link">
                        <i :class="item.icon" style="font-size: 22px;margin-bottom: 4px"></i>
                        {{ item.title }}
                    </router-link>
                </template>
<!--            //</dv-border-box-8>-->


        </div>
        <dv-decoration-3 style="width:93px;height:30px;" />
    </div>

</template>

<script>


export default {
	name: "navItem",
	props: {
		width: {
			type: String, default: '20%'
		},
		item_width: {
			type: String, default: '42%'
		},
		links: {
			type: Array,
			default() {
				return []
			}
		},
		box_height: {
			type: String,
			default() {
				return '110px';
			}
		}
	},
	methods: {
		goHome() {
			this.$router.push('/')
		}
	}

}
</script>

<style scoped lang="less">

.menu-box {

  //background: rgba(255, 255, 255, 0.03);
  margin-right: 3px;
  //border: 1px solid rgba(0,255,255,0.7);
  height: 100%;
  width: 110px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .box {
    display: flex;
    align-items: center;
    flex-direction: column;
    //border: 1px solid rgba(0, 155, 255, 0.5);
    box-shadow: 0px 0px 4px 1px rgba(0, 155, 255, 0.8) ;
    background: linear-gradient(45deg, rgba(0,155,255,.5) 0%,rgba(0,0,0,.5) 50%,rgba(0,155,255,.3) 100%);
    border-radius: 4px;
    overflow: hidden;

    padding: 8px;
    //background: rgba(0, 155, 255, 0.2);
    font-size: 16px;
  }


  .menu_title {
    //padding: 5px;
    width: 75px;
    text-align: center;
    box-sizing: border-box;
    height: 75px;
    padding: 10px 0;
    overflow: hidden;
    //border-bottom: 1px solid rgba(255,255,255,0.3);
    //background: rgba(0, 255, 255, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    color: #0ff;
    text-decoration: none;
    flex-direction: column;
    margin: 5px 0;

    border-radius: 4px;

    &.router-link-active {
      box-shadow: 0px 0px 1px 1px rgba(0, 255, 255, 0.3) ;
      background-image: linear-gradient(180deg, rgba(0,255,255,.5) 0%,rgba(0,255,255,.1) 50%,rgba(0,255,255,.3) 100%) !important;
      color: #fff;
    }

    &:hover {
      background-image: linear-gradient(180deg, rgba(0,255,255,.3) 0%,rgba(0,255,255,.01) 50%,rgba(0,255,255,.3) 100%) !important;
      color: #fff;
    }

  }

}
</style>
