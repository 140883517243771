<template>
    <div class="report-box">
        <NavItem :links="reportLinks" :box_height="box_height" ></NavItem>

        <router-view :box_height="0"></router-view>

    </div>
</template>

<script>
import NavItem from "_c/navItem";
import {reportLinks} from "@/views/data-report/datas";

export default {
	name: "data-report",
	components: {
		NavItem
	},
	data() {
		return {
			reportLinks,
			paths: ['/data-report/center', '/data-report/shop']
		}
	},
	computed: {
		box_height() {
			return Math.ceil(this.reportLinks.length / 2) * 50 + 10 + 'px'
		},
		path() {
			return this.$route.fullPath
		}
	}

}
</script>

<style scoped lang="less">
.report-box {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;


  & > div {
    width: calc(100% - 120px);
    height: 100%;
    display: flex;
  }
}
</style>
